import React, { useState, useEffect } from 'react';
import { string } from 'prop-types';
import { navigate } from 'gatsby';
import { UPDATE_MEDIA_ROUTE, ALL_CARDS_ROUTE } from 'constants/navigation';

import { useNoticationContext } from 'contexts/notification-context';
import { useStateContext } from 'contexts/state-context';

import getDetailedPassService from 'services/passes/get-detailed-pass-service';

import UpdateLoyaltyInformation from 'components/loyalty-card/update-information';

const UpdateCardView = ({ passId }) => {
  const [savedForm, setSavedForm] = useState({});
  const [heldPass, setHeldPass] = useState({});

  const noticationContext = useNoticationContext();
  const stateContext = useStateContext();
  const { getDetailedPassById } = getDetailedPassService({ stateContext, noticationContext });

  useEffect(() => {
    const getSavedFormData = async () => {
      // try
      const passData = await getDetailedPassById({ passId });
      const {
        name = '',
        description = '',
        organization = '',
        address = '',
        requiredLoyaltyAmount = 0,
        locations = [],
        reward = {},
        phoneNumber = {},
        website = {},
      } = passData || {};
      setSavedForm({
        name,
        description,
        organization,
        address,
        requiredLoyaltyAmount,
        locations,
        reward: {
          label: 'Loyalty card rewards',
          ...reward,
        },
        phoneNumber: {
          label: 'Contact us by phone:',
          ...phoneNumber,
        },
        website: {
          label: 'Find us online:',
          ...website,
        },
      });
      setHeldPass(passData);
    };

    getSavedFormData();

    return () => setSavedForm({});
    // eslint-disable-next-line
  }, []);

  return (
      <UpdateLoyaltyInformation
        savedForm={savedForm}
        saveForm={async (data) => {
          const getUpdatePassService = await import('services/passes/update-pass-service');
          const { updateLoyaltyPass } = getUpdatePassService.default({
            stateContext,
            noticationContext,
          });
          const pass = {
            passId,
            ...heldPass,
            ...data,
          };
          const updatedPass = await updateLoyaltyPass({ pass });
          if (updatedPass && updatedPass.passId) {
            navigate(`${UPDATE_MEDIA_ROUTE}?passId=${updatedPass.passId}`);
          } else {
            navigate(ALL_CARDS_ROUTE);
          }
        }}/>
  );
};

UpdateCardView.propTypes = { passId: string.isRequired };

export default UpdateCardView;
