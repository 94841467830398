import { buildLogger, LoggableError } from 'logger';
import StackTrace from 'stacktrace-js';
import { getDetailedPassById } from 'data/oncion';
import buildDataLayer from 'data-layer';
import { gaLoggedInLabel, getDetailedPass } from 'state/selectors';

export default ({ stateContext, noticationContext }) => {
  const logger = buildLogger();
  const dataLayer = buildDataLayer();

  const { dispatch, getToken } = stateContext;
  const { showNotification, types } = noticationContext;

  const onError = ({
    error = null,
    notification = 'There has been a problem, sorry please try again',
    caller = 'get-detailed-pass-service',
    trace,
  } = {}) => {
    showNotification({ message: notification, type: error ? types.error : types.info });
    if (error) {
      const { stack = '', message = '' } = error;
      logger.error(new LoggableError({
        data: { stack }, message, caller, trace,
      }));
    }
  };

  return {
    getDetailedPassById: async ({ passId }) => {
      const heldPass = await getDetailedPass();
      if (heldPass?.passId === passId) {
        logger.info({ caller: 'get-detailed-pass-service:getDetailedPassById', message: 'cached' });
        return heldPass;
      }

      try {
        const getLoggedInService = await import('services/logged-in-service');
        const { loggedIn } = getLoggedInService.default({
          stateContext,
        });
        const isLoggedIn = await loggedIn();

        if (!isLoggedIn) {
          logger.info({ caller: 'get-detailed-pass-service:getDetailedPassById', message: 'logged-out' });
          dispatch({ type: 'remove-detailed-pass' });
          return {};
        }

        const { token } = await getToken();
        const passData = await getDetailedPassById({ token, passId });
        dispatch({ type: 'set-detailed-pass', payload: { detailedPass: passData } });
        dataLayer.trackPass({
          descriptor: 'get_pass_detailed_success',
          label: `${gaLoggedInLabel()}`,
        });
        return passData;
      } catch (error) {
        const trace = await StackTrace.get();
        onError({ error, caller: 'get-detailed-pass-service:getDetailedPassById', trace });
        dataLayer.trackPass({
          descriptor: 'get_pass_detailed_error',
          label: `${gaLoggedInLabel()}`,
        });
        return {};
      }
    },
  };
};
