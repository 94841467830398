import React from 'react';
import { func, object } from 'prop-types';

import LoyaltyInformationForm from 'components/loyalty-card/create-information';

const UpdateLoyaltyInformation = ({ savedForm, saveForm }) => (
    <LoyaltyInformationForm saveForm={saveForm} savedForm={savedForm}/>
);

UpdateLoyaltyInformation.propTypes = {
  saveForm: func.isRequired,
  savedForm: object,
};

export default UpdateLoyaltyInformation;
